import React from 'react';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import FAQItem from '../../components/FAQ-item';
import Tabs from '../../components/tabs';

import {tabs} from '../../helpers/constants/index';

import './style.scss';

const Faq: React.FC = (): JSX.Element => {
  return (
    <Layout>
      <PageHero title="Hexometer FAQ" subtitle="In this page you can get the answers to FAQs" />
      <div className="faq-tabs">
        <Tabs dataTabs={tabs} />
      </div>
      <Section>
        <div className="col-12 d-block mb_20" style={{maxWidth: '1200px', width: '100%'}}>
          <FAQItem
            title="What is Hexometer?"
            text={
              <>
                <p>
                  Hexometer is your AI sidekick that works behind the scenes, monitoring your website and marketing
                  24/7. Detect downtime and fix page speed performance issues, security vulnerabilities, page & JS
                  errors, email deliverability, SEO optimization or server configuration problems before they burn a
                  hole in your pocket. Get started in minutes - no code changes required.
                </p>
              </>
            }
          />
          <FAQItem
            title="How does uptime monitoring work?"
            text={
              <>
                <p>Hexometer provides 24/7 peace of mind by continuously monitoring all your website pages for:</p>
                <ul>
                  <li>
                    <span>Uptime</span>
                  </li>
                  <li>
                    <span>HTTP responses for every page on your website</span>
                  </li>
                  <li>
                    <span>DNS records</span>
                  </li>
                  <li>
                    <span>SSL certificates</span>
                  </li>
                  <li>
                    <span>Security headers</span>
                  </li>
                </ul>
              </>
            }
          />
          <FAQItem
            title="How does Performance monitoring work?"
            text={
              <>
                <p>
                  Hexometer monitors your most important pages daily, helping you track and improve your performance via
                  actionable insights.
                </p>
                <ul>
                  <li>
                    <span>Analyze website performance from desktop, tablet and mobile devices</span>
                  </li>
                  <li>
                    <span>Monitor page speed</span>
                  </li>
                  <li>
                    <span>Get actionable insights to fix performance issues</span>
                  </li>
                  <li>
                    <span>Check how your home page display in different devices</span>
                  </li>
                </ul>
              </>
            }
          />
          <FAQItem
            title="How does Hexometer check my website for errors and broken pages?"
            text={
              <>
                <p>
                  Maintaining a website is a full-time job; every website update, WordPress version change, plugin
                  update, website modification or 3rd party script has the potential to break things. Whilst a visual
                  bug might lower your conversion rate, a broken functionality like a shopping cart is much more costly.
                  Hexometer checks all your web pages for broken links, server errors, images that don't display and JS
                  errors so you can pinpoint and fix problems before they affect your business.
                </p>
              </>
            }
          />
          <FAQItem
            title="How does Hexometer help with my SEO rankings?"
            text={
              <>
                <p>
                  Hexometer performs an SEO audit of all your pages on a weekly or daily basis depending on your plan,
                  checking your website for on-site issues that can affect your search engine rankings as well as
                  identify areas for optimization.
                </p>
                <h6>Some of the factors we check include:</h6>
                <ul>
                  <li>
                    <span>Title and description meta tags</span>
                  </li>
                  <li>
                    <span>Duplicate content issues</span>
                  </li>
                  <li>
                    <span>Broken links</span>
                  </li>
                  <li>
                    <span>Header Structure</span>
                  </li>
                  <li>
                    <span>Sitemap issues</span>
                  </li>
                  <li>
                    <span>Open Graph optimizations</span>
                  </li>
                </ul>
              </>
            }
          />
          <FAQItem
            title="What security monitoring does Hexometer provide?"
            text={
              <>
                <p>
                  Our AI assistant monitors your homepage for known malware and malicious code. We also check your
                  security headers, SSL certificate and if your website is blacklisted by the leading security
                  authorities.
                </p>
              </>
            }
          />
          <FAQItem
            title="Which notification channels does Hexometer support to let you know when something goes wrong?"
            text={
              <>
                <p>Hexometer can alert you when an issue happens via:</p>
                <ul>
                  <li>
                    <span>Email</span>
                  </li>
                  {/* <li>
                    <span>SMS</span>
                  </li> */}
                  <li>
                    <span>Telegram </span>
                  </li>
                  <li>
                    <span>Slack </span>
                  </li>
                  <li>
                    <span>Discord</span>
                  </li>
                  <li>
                    <span>Trello</span>
                  </li>
                </ul>
                <p>
                  Hexometer is also integrated with Google sheets, also with Zapier, which enables you to get alert
                  notifications to up to 2000 different apps in the Zapier marketplace.
                </p>
              </>
            }
          />
          <FAQItem
            title="How to filter Hexometer traffic in Google Analytics?"
            text={
              <>
                <ol>
                  <li>Select the Property in your Google Analytics account and click Filters.</li>
                  <li>Click Add filter</li>
                  <li>Type Filter name</li>
                  <li>Select Custom - Exclude</li>
                  <li>Select Browser in the Filter Field</li>
                  <li>Type Hexometer</li>
                  <li>Save</li>
                </ol>
                <div className="hexometer-faq-img-cls">
                  <img src="https://gblobscdn.gitbook.com/assets%2F-LjURmlCMFduFZIqWo5A%2F-M0WTxETcEZ9OidgTyrs%2F-M0WWb6x01-gmdJIJ8do%2Fhexometer_browser_filter.jpg?alt=media&amp;token=b51385c3-22f0-46f0-8a8c-e49de002b2e0"></img>
                  <span>Add filter screen from Google Analytics</span>
                </div>
              </>
            }
          />
          <FAQItem
            title="What is Meta Tags Scanner?"
            text={
              <>
                <p>
                  Website <a href="https://hexometer.com/meta-tag-analyzer/">Meta Tags Analyzer</a> scans your web
                  page's Title and Description Tags and checks for compliance to best practice.
                </p>
              </>
            }
          />
          <FAQItem
            title="What is Domain Whois tool?"
            text={
              <>
                <p>
                  Domain Whois service allows you to get any public information about a domain name. When you enter a
                  domain name into the Hexometer{' '}
                  <a href="https://hexometer.com/domain-whois/">Domain Whois Lookup Service</a> search bar, data from
                  the Whois record, as well as other information available for Hexometer, are returned in a result page.
                  Whois records can contain information about the registrar, domain name owner and contact information,
                  the domain's name server, and the dates of registration and expiration.
                </p>
              </>
            }
          />
          <FAQItem
            title="What is the Broken Links tool?"
            text={
              <>
                <p>
                  <a href="https://hexometer.com/broken-links/">Broken Link Checker</a> Tool helps you to scan and
                  detect the dead (or "rotten") links on your website for you to fix them.
                </p>
              </>
            }
          />
          <FAQItem
            title="What is Tech Stack Checker?"
            text={
              <>
                <p>
                  <a href="https://hexometer.com/stack-checker/">Website Technology Stack Checker </a>
                  Tool helps you to detect what kind of technologies are used in the mentioned website, including coding
                  languages,
                  <a href="https://resources.hexometer.com/wiki/terminology#cms"> CMS</a>, marketing tools, e-commerce
                  solutions and more.
                </p>
              </>
            }
          />
          <FAQItem
            title="What is W3C Validator?"
            text={
              <>
                <p>
                  <a href="https://hexometer.com/w3c-validator/">W3C Markup Validation Tool</a> checks the markup
                  validity of Web documents in HTML, XHTML, SMIL, MathML, etc. You can read more at:
                  <a href="https://en.wikipedia.org/wiki/World_Wide_Web_Consortium" target="_blank" rel="noopener">
                    {' '}
                    https://en.wikipedia.org/wiki/World_Wide_Web_Consortium
                  </a>
                </p>
              </>
            }
          />
          <FAQItem
            title="What is SSL Checker?"
            text={
              <>
                <p>
                  <a href="https://hexometer.com/ssl-certificate-checker/">SSL Certificate Checker </a> tool will help
                  you diagnose whether the website has an SSL Certificate or not, and will retrieve any public data
                  connected with it. You can verify the SSL certificate, to make sure it is correctly installed, valid,
                  trusted and doesn't give any errors to any of your users.
                </p>
              </>
            }
          />
          <FAQItem
            title="What is Page Analyzer?"
            text={
              <>
                <p>
                  <a href="https://hexometer.com/page-analyzer/">Page Assets Analyser </a>
                  helps you to scan a web page and detect every JS, CSS, Image, and other file size and count, so you
                  could beat the upcoming issues.
                </p>
              </>
            }
          />
          <FAQItem
            title="What is IP Lookup tool?"
            text={
              <>
                <p>
                  <a href="https://hexometer.com/ip-lookup/">IP Address Lookup </a> tool helps to find out the country,
                  owner and more available data about IP address.
                </p>
              </>
            }
          />
          <FAQItem
            title="What is Page Speed Scanner?"
            text={
              <>
                <p>
                  <a href="https://hexometer.com/page-speed-scanner/">Page Load Analyzer </a> helps to detect website
                  load performance figures for future optimization
                </p>
              </>
            }
          />
        </div>
      </Section>
    </Layout>
  );
};

export default Faq;
